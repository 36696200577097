<template>
  <b-overlay
    :show="show"
    spinner-variant="primary"
    spinner-type="grow"
    spinner-small
    rounded="sm"
    height="100%"
  >
    <RedirectAlert
      :is-show="!isEnabledBankDeposit"
      route-name="tickets-create"
      :alert-text="$t('disabled_bank_deposit')"
      :button-text="$t('deposit_with_bank')"
    />
    <b-card
      v-if="sendReceiptCard && isEnabledBankDeposit"
      class="d-flex justify-content-center"
    >
      <b-row v-if="alertShow">
        <b-col sm="12">
          <b-alert
            variant="success"
            show
            class="px-1"
          >
            <div class="alert-body p-2">
              <h4>
                {{ $t("successful-deposit-registration") }}
              </h4>
            </div>
          </b-alert>
        </b-col>
      </b-row>
      <b-row>
        <b-col
          sm="12"
          lg="6"
          class="card-border"
        >
          <div>
            <h3
              :class="
                $store.state.appConfig.layout.skin === 'dark'
                  ? 'text-white'
                  : 'text-primary'
              "
            >
              {{ $t("wireDepositTitle") }}
            </h3>
            <h5 class="mb-3">
              {{ $t("wireDepositDescription") }}
            </h5>
            <p
              v-if="adminCardInfo.max_deposit_amount"
              class="my-1 text-warning"
            >
              {{
                $t("prices_upper_10million_descriptin", {
                  max_deposit_amount: numberWithCommas(
                    adminCardInfo.max_deposit_amount
                  ),
                  currency: adminCardInfo.currency,
                })
              }}
            </p>
            <b-alert
              v-if="adminCardInfo.deposit_transaction_number"
              show
              variant="warning"
              class="my-2"
            >
              <h5 class="alert-heading">
                {{ $t("deposit-id") }}
              </h5>
              <div class="mt-1 alert-body">
                <h5 class="text-justify">
                  {{ $t("deposit-id-desc") }}
                </h5>
              </div>
            </b-alert>
            <div>
              <b-card class="bg-card text-white">
                <b-row>
                  <b-col class="text-center text-lg-right w-75">
                    <span class="pa-2 d-block mb-1 d-block d-lg-none">{{
                      $t("cardNumber")
                    }}</span>
                    <span class="pa-2 d-block font-weight-bolder">{{
                      adminCardInfo.card_number
                    }}</span>
                    <br>
                    <span class="pa-2 d-block mb-1 d-block d-lg-none">{{
                      $t("accountNumber")
                    }}</span>
                    <span class="pa-2 d-block font-weight-bolder">{{
                      adminCardInfo.account_number
                    }}</span>
                    <br>
                    <span class="pa-2 d-block mb-1 d-block d-lg-none">{{
                      $t("iban")
                    }}</span>
                    <span class="pa-2 d-block font-weight-bolder">{{
                      adminCardInfo.iban
                    }}</span>
                    <br>
                    <span class="pa-2 d-block mb-1 d-block d-lg-none">{{
                      $t("card_name")
                    }}</span>
                    <span class="pa-2 d-block font-weight-bolder">{{
                      adminCardInfo.card_name
                    }}</span>
                    <br>
                    <span class="pa-2 d-block mb-1 d-block d-lg-none">{{
                      $t("bank_name")
                    }}</span>
                    <span class="pa-2 d-block font-weight-bolder">
                      {{ adminCardInfo.bank_name }}</span>
                    <br>
                    <span v-if="adminCardInfo.deposit_transaction_number">
                      <span class="pa-2 d-block mb-1 d-block d-lg-none">{{
                        $t("deposit-id")
                      }}</span>
                      <span class="pa-2 d-block font-weight-bolder">
                        {{ adminCardInfo.deposit_transaction_number }}</span>
                    </span>
                  </b-col>
                  <b-col
                    cols="1"
                    class="text-center text-lg-right w-25 d-none d-lg-block"
                  >
                    <span class="pa-2 d-block">:</span>
                    <br>
                    <span class="pa-2 d-block">:</span>
                    <br>
                    <span class="pa-2 d-block">:</span>
                    <br>
                    <span class="pa-2 d-block">:</span>
                    <br>
                    <span class="pa-2 d-block">:</span>
                    <br>
                    <span
                      v-if="adminCardInfo.deposit_transaction_number"
                      class="pa-2 d-block"
                    >:</span>
                  </b-col>
                  <b-col
                    cols="3"
                    class="text-center text-lg-right w-25 d-none d-lg-block"
                  >
                    <span class="pa-2 text-nowrap d-block font-weight-bolder">{{
                      $t("cardNumber")
                    }}</span>
                    <br>
                    <span class="pa-2 text-nowrap d-block font-weight-bolder">{{
                      $t("accountNumber")
                    }}</span>

                    <br>
                    <span class="pa-2 d-block font-weight-bolder">{{
                      $t("iban")
                    }}</span>
                    <br>
                    <span class="pa-2 d-block font-weight-bolder">{{
                      $t("card_name")
                    }}</span>
                    <br>
                    <span class="pa-2 d-block font-weight-bolder">{{
                      $t("bank_name")
                    }}</span>
                    <br>
                    <span
                      v-if="adminCardInfo.deposit_transaction_number"
                      class="pa-2 d-block font-weight-bolder"
                    >{{ $t("deposit-id") }}</span>
                  </b-col>
                </b-row>
              </b-card>
            </div>
          </div>
        </b-col>

        <b-col
          sm="12"
          lg="6"
        >
          <b-alert
            v-if="buyConvertRate || sellConvertRate"
            show
            class="p-1"
          >
            <b-row>
              <b-col
                v-if="buyConvertRate"
                cols="12"
                sm="6"
              >
                <p
                  :class="
                    $store.state.appConfig.layout.skin === 'dark' &&
                      'text-white'
                  "
                >
                  <span>{{ $t("convert_rate_buy") }}</span>
                  <span style="margin: 0 4px">:</span>
                  <span dir="ltr">{{
                    numberWithCommas(buyConvertRate) + " " + currency.currency
                  }}</span>
                </p>
              </b-col>
              <b-col
                v-if="sellConvertRate"
                cols="12"
                sm="6"
              >
                <p
                  :class="
                    $store.state.appConfig.layout.skin === 'dark' &&
                      'text-white'
                  "
                >
                  <span>{{ $t("convert_rate_sell") }}</span>
                  <span style="margin: 0 4px">:</span>
                  <span dir="ltr">{{
                    numberWithCommas(sellConvertRate) + " " + currency.currency
                  }}</span>
                </p>
              </b-col>
            </b-row>
          </b-alert>
          <div class="my-2">
            <metatraderAccountDetail :acc="accountDetail" />
          </div>
          <b-alert
            v-show="accountDetail.max_deposit || accountDetail.min_deposit"
            id="max-min-deposit-alert"
            show
            variant="warning"
            class="p-1"
          >
            <div class="alert-body">
              <p class="text-justify">
                {{
                  $t("deposit-msg", {
                    group: accountDetail.group,
                    min: numberWithCommas(accountDetail.min_deposit || 0),
                    max: numberWithCommas(accountDetail.max_deposit || 0),
                  })
                }}
              </p>
              <p
                v-if="accountDetail.min_deposit"
                class="text-justify"
              >
                {{
                  $t("min-deposit-msg", {
                    min: numberWithCommas(accountDetail.min_deposit || 0),
                  })
                }}
              </p>
              <p
                v-if="accountDetail.max_deposit"
                class="text-justify"
              >
                {{
                  $t("max-deposit-msg", {
                    max: numberWithCommas(accountDetail.max_deposit || 0),
                  })
                }}
              </p>
            </div>
          </b-alert>
          <validation-observer ref="depositform">
            <b-form>
              <b-row>
                <b-col md="6">
                  <b-form-group
                    :label="$t('forex.account-number')"
                    label-for="account-number"
                  >
                    <validation-provider
                      v-slot="{ errors }"
                      :name="$t('forex.account-number')"
                      vid="account-number"
                      rules="required"
                    >
                      <v-select
                        id="account-number"
                        v-model="accSelected"
                        :options="accounts"
                        :clearable="false"
                        label="account"
                        @input="onchangeAccountDetail"
                      />

                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <b-col md="6">
                  <b-form-group
                    :label="$t('forex.platform')"
                    label-for="platform"
                  >
                    <validation-provider
                      v-slot="{ errors }"
                      :name="$t('forex.platform')"
                      vid="platform"
                      rules="required"
                    >
                      <v-select
                        id="platform"
                        v-model="platformSelected"
                        :options="platform"
                        label="name"
                        disabled
                        :clearable="false"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <b-col md="6">
                  <b-form-group
                    label-for="Currency"
                    :label="$t('fiat_wallet_deposit_currency')"
                  >
                    <validation-provider
                      #default="{ errors }"
                      :name="$t('fiat_wallet_deposit_currency')"
                      vid="Currency"
                      rules="required"
                      :required="!currency"
                    >
                      <v-select
                        id="Currency"
                        v-model="currency"
                        :options="currencies"
                        :placeholder="$t('fiat_wallet_deposit_currency')"
                        label="fullname"
                        name="fullname"
                        track-by="fullname"
                        :clearable="false"
                        @input="changeCurrency"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <b-col
                  md="6"
                  sm="12"
                  xl="6"
                >
                  <b-form-group
                    :label="$t('fiat_wallet_deposit_amount')"
                    label-for="hear-description"
                  >
                    <validation-provider
                      v-slot="{ errors }"
                      :name="$t('fiat_wallet_deposit_amount')"
                      rules="required|number_or_decimal|decimal_points:2"
                    >
                      <b-form-input
                        v-model="amount"
                        name="hear-description"
                        :placeholder="$t('fiat_wallet_deposit_amount')"
                        autocomplete="nope"
                        :formatter="numberFormat"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <b-col>
                  <b-form-group
                    label-for="file"
                    :label="$t('deposit_Receipt')"
                  >
                    <validation-provider
                      #default="{ errors }"
                      :name="$t('deposit_Receipt')"
                      :rules="'image|required|size:' + MAX_IMAGE_UPLOAD_SIZE"
                    >
                      <b-form-file
                        id="file-large"
                        v-model="file"
                        accept="image/png, image/jpg, image/jpeg"
                        :placeholder="$t('fiat_wallet_deposit_receipt_file')"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
              </b-row>
            </b-form>
          </validation-observer>
          <br>
          <b-row v-if="$options.components.bonusForm">
            <b-col>
              <component
                :is="'bonusForm'"
                @onAccepted="setBonusCode"
              />
            </b-col>
          </b-row>
          <b-col cols="12">
            <b-button
              v-if="!pending"
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="mt-2 w-100"
              @click="save"
            >
              {{ $t("deposit-request") }}
            </b-button>
            <b-button
              v-if="pending"
              variant="primary"
              class="mt-1 mr-1"
              disabled
            >
              <b-spinner
                small
                type="grow"
              />
              {{ $t("deposit-request") }}
            </b-button>
          </b-col>
        </b-col>
      </b-row>
    </b-card>
    <b-card v-if="!sendReceiptCard">
      <h4
        class="text-justify"
        style="line-height: normal"
      >
        {{ $t("deposit-desc") }}
      </h4>
      <div
        v-if="
          $store.state.profile.profileInfo.document.general.status !== 'success'
        "
      >
        <h4 style="line-height: normal">
          {{ $t("deposit-desc2") }}
        </h4>
        <b-button
          class="mt-1"
          variant="primary"
          :to="
            $store.state.profile.profileInfo.profile_complete
              ? `/${$i18n.locale}/document/general`
              : `/${$i18n.locale}/profile/general`
          "
        >
          {{ $t("deposit-btn") }}
        </b-button>
      </div>
    </b-card>
    <b-card
      v-if="items.length > 0"
      class="d-none d-lg-block"
    >
      <b-row align-h="center">
        <b-col cols="12">
          <b-table
            :items="items"
            :fields="fields"
            striped
            sticky-header="1000px"
          >
            <template #cell(date)="data">
              <span>{{ getFormattedDate(data.item["date"]) }}</span>
            </template>
            <template #cell(amount)="data">
              <span>{{ numberWithCommas(data.item.amount) }}</span>
            </template>
            <template #cell(status)="data">
              <div class="d-flex align-items-center">
                <b-button
                  :variant="
                    data.item.detail['status'] === 'pending'
                      ? 'warning'
                      : data.item.detail['status'] === 'successful'
                        ? 'success'
                        : data.item.detail['status']
                          ? 'danger'
                          : ''
                  "
                  size="sm"
                  style="display: block; width: 140px"
                  @click="openInfoTransactionModal(data.item)"
                >
                  {{ $t(data.item.detail.status) }}</b-button>
                <feather-icon
                  v-if="
                    data.item.detail['status'] === 'rejected' &&
                      data.item.detail['reject_reason']
                  "
                  :id="`popover-button-variant-${data.item.id}`"
                  icon="HelpCircleIcon"
                  class="cursor-pointer mx-1"
                  size="20"
                  href="#"
                  tabindex="0"
                />
                <b-popover
                  v-if="
                    data.item.detail['status'] === 'rejected' &&
                      data.item.detail['reject_reason']
                  "
                  placement="top"
                  :target="`popover-button-variant-${data.item.id}`"
                  variant="secondary"
                  triggers="focus"
                >
                  {{
                    data.item.detail["reject_reason"]
                      ? data.item.detail["reject_reason"]
                      : ""
                  }}
                </b-popover>
              </div>
            </template>
          </b-table>
        </b-col>
        <b-col cols="auto">
          <b-pagination
            v-if="totalTransactions > perPageTransactions"
            v-model="currentpageTransactions"
            :total-rows="totalTransactions"
            :per-page="perPageTransactions"
            align="fill"
            size="sm"
            class="mb-1"
          />
        </b-col>
      </b-row>
    </b-card>
    <b-card
      v-if="items.length > 0"
      class="d-lg-none"
      :title="$t('lastDeposits')"
    >
      <b-row align-h="center">
        <b-col cols="12">
          <div
            v-for="(item, index) in items"
            :key="index"
            :class="
              $store.state.appConfig.layout.skin === 'dark'
                ? 'card-bg'
                : 'transaction-card'
            "
            class="mb-2"
          >
            <div class="d-flex justify-content-between">
              <div>{{ $t("id") }}</div>
              <h5>{{ item.automation_id }}</h5>
            </div>
            <div class="d-flex justify-content-between mt-1">
              <div>{{ $t("forex.account-number") }}</div>
              <h5>{{ item.account }}</h5>
            </div>
            <div class="d-flex justify-content-between mt-1">
              <div>{{ $t("forex.amount") }}</div>
              <h5>{{ numberWithCommas(item.amount) }}</h5>
            </div>
            <div class="d-flex justify-content-between mt-1">
              <div>{{ $t("date") }}</div>
              <h5>{{ getFormattedDate(item.date) }}</h5>
            </div>
            <div class="d-flex justify-content-between mt-1">
              <div>{{ $t("forex.destination-account") }}</div>
              <h5 class="text-break">
                {{ item.admin_bank.card_number }}
              </h5>
            </div>
            <div class="d-flex justify-content-between mt-1">
              <div>{{ $t("forex.currency") }}</div>
              <h5>{{ item.currency.currency }}</h5>
            </div>
            <div class="d-flex justify-content-between mt-1">
              <div>{{ $t("status") }}</div>
              <div class="d-flex align-items-center">
                <feather-icon
                  v-if="
                    item.detail['status'] === 'rejected' &&
                      item.detail.reject_reason
                  "
                  :id="`popover-button-variant-${item.id}+mobile`"
                  icon="HelpCircleIcon"
                  class="cursor-pointer"
                  size="20"
                  href="#"
                  tabindex="0"
                />
                <b-popover
                  v-if="
                    item.detail['status'] === 'rejected' &&
                      item.detail.reject_reason
                  "
                  placement="top"
                  :target="`popover-button-variant-${item.id}+mobile`"
                  variant="secondary"
                  triggers="focus"
                >
                  {{
                    item.detail.reject_reason ? item.detail.reject_reason : ""
                  }}
                </b-popover>
                <div style="margin: 0 5px" />
                <b-button
                  :variant="
                    item.detail['status'] === 'pending'
                      ? 'warning'
                      : item.detail['status'] === 'successful'
                        ? 'success'
                        : item.detail['status']
                          ? 'danger'
                          : ''
                  "
                  size="sm"
                  style="display: block; width: 140px"
                >
                  {{ $t(item.detail.status) }}</b-button>
              </div>
            </div>
          </div>
        </b-col>
        <b-col cols="auto">
          <b-pagination
            v-if="totalTransactions > perPageTransactions"
            v-model="currentpageTransactions"
            :total-rows="totalTransactions"
            :per-page="perPageTransactions"
            align="fill"
            size="sm"
            class="mt-2 mb-1"
          />
        </b-col>
      </b-row>

      <b-modal
        v-model="infoTransactionModal"
        :title="
          selectedTransaction
            ? $t('info_transaction_modal', { id: selectedTransaction[0].id })
            : ''
        "
        hide-footer
        size="lg"
        modal-class="transaction-modal"
      >
        <b-alert
          v-if="
            selectedTransaction && selectedTransaction[0].detail.reject_reason
          "
          variant="danger"
          show
        >
          <h5 class="alert-heading">
            {{ $t("rejectDescription") }}:
          </h5>
          <div class="alert-body p-2">
            <p>
              {{
                selectedTransaction &&
                  selectedTransaction[0].detail.reject_reason
              }}
            </p>
          </div>
        </b-alert>
        <!-- request info -->
        <h5 class="mt-1">
          {{ $t("request_info") }}
        </h5>
        <b-table
          hover
          responsive
          :items="selectedTransaction"
          :fields="transactionModalRequestFields"
        />
        <hr>
        <!-- request destination -->
        <h5 class="mt-2">
          {{ $t("account_destination") }}
        </h5>
        <b-table
          hover
          responsive
          :items="selectedTransaction"
          :fields="transactionModalAccountFields"
        />
      </b-modal>
    </b-card>
  </b-overlay>
</template>
<script>
import {
  BOverlay,
  BFormInput,
  BFormGroup,
  BCard,
  BRow,
  BCol,
  BFormFile,
  BButton,
  BForm,
  BSpinner,
  BAlert,
  BTable,
  BPagination,
  BPopover,
} from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  required, size, numberOrDecimal, decimalPoints,
} from '@validations'
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'
import RedirectAlert from '@/views/components/RedirectAlert.vue'
import { alphanumeric } from '@/mixins/alphanumeric'
import MetatraderApis from '@/modules/forex/service/apis'
import FiatApis from '@/modules/crypto/services/apis/fiat'
import metatraderAccountDetail from '@/modules/forex/views/metatrader/metatraderAccountDetail.vue'
import moment from '@/libs/moment'
import { numberWithCommas, resizeImage } from '@/utils'
import ProfileApis from '@/services/apis/profile'

const metatrader = new MetatraderApis()
const fiat = new FiatApis()
const profile = new ProfileApis()
export default {
  components: {
    BOverlay,
    BFormInput,
    BForm,
    BFormGroup,
    BCard,
    BRow,
    BCol,
    BFormFile,
    vSelect,
    ValidationProvider,
    ValidationObserver,
    BSpinner,
    BButton,
    BAlert,
    metatraderAccountDetail,
    BTable,
    BPagination,
    BPopover,
    RedirectAlert,
    bonusForm: () => process.env.VUE_APP_ACTIVE_MODULES.split(',').includes('BONUS')
      && import('@/modules/bonus/views/components/form.vue'),
  },
  directives: {
    Ripple,
  },
  mixins: [alphanumeric],
  data() {
    return {
      show: false,
      alertShow: false,
      sendReceiptCard: true,
      pending: false,
      required,
      size,
      numberOrDecimal,
      decimalPoints,
      options: [],
      amount: '',
      file: [],
      bankAccountInfo: null,
      usdSellPrice: null,
      accounts: [],
      accSelected: {},
      platform: [],
      currencies: [],
      platformSelected: '',
      currency: '',
      accountDetail: {
        account: '',
        balance: '',
        equity: '',
        group: '',
        leverage: '',
        margin: '',
        platform: '',
      },
      bonusCode: '',
      items: [],
      adminCardInfo: '',
      currentpageTransactions: 1,
      totalTransactions: 0,
      perPageTransactions: 10,
      numberWithCommas,
      sellConvertRate: null,
      buyConvertRate: null,
      infoTransactionModal: false,
      selectedTransaction: null,
    }
  },
  computed: {
    MAX_IMAGE_UPLOAD_SIZE() {
      return process.env.VUE_APP_MAX_IMAGE_UPLOAD_SIZE
    },
    fields() {
      return [
        {
          label: this.$t('id'),
          key: 'automation_id',
          tdClass: 'nameOfTheClass',
        },
        {
          label: this.$t('forex.account-number'),
          key: 'account',
          tdClass: 'nameOfTheClass',
        },
        {
          label: this.$t('forex.amount'),
          key: 'amount',
          tdClass: 'nameOfTheClass',
        },

        {
          label: this.$t('date'),
          key: 'date',
          tdClass: 'nameOfTheClass',
        },
        {
          label: this.$t('forex.destination-account'),
          key: 'admin_bank.card_number',
          tdClass: 'nameOfTheClass',
        },
        {
          label: this.$t('currency'),
          key: 'currency.currency',
          tdClass: 'nameOfTheClass',
        },
        {
          label: this.$t('status'),
          key: 'status',
          tdClass: 'nameOfTheClass',
        },
      ]
    },
    transactionModalRequestFields() {
      return [
        {
          label: this.$t('account'),
          key: 'account',
        },
        {
          label: this.$t('amount'),
          key: 'amount',
        },
        {
          label: this.$t('currency'),
          key: 'currency',
        },
      ]
    },
    transactionModalAccountFields() {
      return [
        {
          label: this.$t('accountNumber'),
          key: 'admin_bank.account_number',
        },
        {
          label: this.$t('card_name'),
          key: 'admin_bank.bank_name',
        },
        {
          label: this.$t('bank_name'),
          key: 'admin_bank.card_number',
        },
      ]
    },
    isEnabledBankDeposit() {
      const isEnabled = process.env.VUE_APP_IS_ENABLED_FOREX_BANK_DEPOSIT
      return (
        isEnabled === undefined || isEnabled === 'true' || isEnabled === ''
      )
    },
  },
  watch: {
    currentpageTransactions(val) {
      if (val) {
        this.depositList()
      }
    },
    '$store.state.Messaging.event': {
      async handler(newVal) {
        if (newVal === 'platform_forex_deposit_wire') {
          await this.getData()
          this.$store.dispatch('Messaging/setEvent', '')
        }
      },
      immediate: true,
    },
  },
  async mounted() {
    this.show = true
    await this.getCurrency()
    await this.getData()
    await this.depositList()
    await this.adminBankCard()
    this.show = false
  },
  methods: {
    async getData() {
      await Promise.all([
        metatrader.getPlatforms().then(res => {
          this.platform = res.data.results
        }),
        metatrader.getAccounts().then(res => {
          this.accounts = res.data.results

          // eslint-disable-next-line prefer-destructuring
          this.accSelected = res.data.results[0]
          // eslint-disable-next-line prefer-destructuring
          if (res.data.results[0]) this.accountDetail = res.data.results[0]
          this.platformSelected = this.platform.find(
            plat => plat.value === this.accountDetail.platform,
          )
          this.getConvertRate('USD', this.currency.currency)

          if (this.$route.params.id) {
            const accountDetail = res.data.results.find(
              item => item.account === parseInt(this.$route.params.id, 10),
            )
            this.accSelected = accountDetail
            this.accountDetail = accountDetail
            this.platformSelected = this.platform.find(
              plat => plat.value === accountDetail.platform,
            )
          }
        }),
      ]).catch(err => {
        console.log(err)
      })
    },
    onchangeAccountDetail(item) {
      this.accountDetail = item
      this.platformSelected = this.platform.find(
        plat => plat.value === item.platform,
      )
      const alert = document.getElementById('max-min-deposit-alert')
      const isMobile = window.matchMedia('(max-width: 767px)').matches
      if ((item.max_deposit || item.min_deposit) && alert && isMobile) {
        alert.scrollIntoView()
      }
    },
    async depositList() {
      await metatrader
        .wireDepositList({
          current_page: this.currentpageTransactions,
          per_page: this.perPageTransactions,
        })
        .then(res => {
          this.items = res.data.results.data
          this.totalTransactions = res.data.results.paginate.total
          this.currentpageTransactions = res.data.results.paginate.current_page
          this.perPageTransactions = res.data.results.paginate.per_page
        })
    },
    async getCurrency() {
      await metatrader.currencyList({ type: 'fiat' }).then(res => {
        const Fcurrencies = [
          ...res.data.results
            .filter(curr => curr.is_deposit)
            .map(curr => ({
              ...curr,
              fullname: `${curr.name} (${curr.currency})`,
            })),
        ]
        this.currencies = Fcurrencies
        this.currency = Fcurrencies[0] ? Fcurrencies[0] : ''
      })
    },
    async save() {
      const valid = await this.$refs.depositform.validate()
      if (valid) {
        this.pending = true
        const compressedFile = await resizeImage(this.file)
        const data = {
          account: this.accSelected.account,
          platform: this.platformSelected.value,
          amount: this.amount.replace(/,/g, ''),
          file: compressedFile,
          admin_bank_id: this.adminCardInfo.id,
          currency: this.currency.currency,
          ...(this.bonusCode && { bonus_code: this.bonusCode }),
        }
        await metatrader
          .saveWireDeposit(data)
          .then(res => {
            this.$swal({
              title: res.data.message,
              icon: 'success',
              timer: 2500,
              showConfirmButton: false,
            })
            this.sendReceiptCard = false
            this.depositList()
            profile.get()
          })
          .finally(() => {
            this.pending = false
          })
      }
    },
    async adminBankCard() {
      await metatrader.bankList().then(res => {
        // eslint-disable-next-line prefer-destructuring
        this.adminCardInfo = res.data.results[0]
      })
    },
    getFormattedDate(date) {
      return moment(date).format('YYYY-MM-DD HH:mm:ss')
    },
    numberFormat(value) {
      // eslint-disable-next-line eqeqeq
      return this.numberWithCommas(value)
    },
    getConvertRate(from, to) {
      fiat
        .getCurrency({
          to_currency: to,
          from_currency: from,
        })
        .then(res => {
          this.buyConvertRate = res.data.results.prices[0]?.buy
          this.sellConvertRate = res.data.results.prices[0]?.sell
        })
    },
    changeCurrency({ currency }) {
      this.getConvertRate('USD', currency)
    },
    openInfoTransactionModal(val) {
      this.infoTransactionModal = true
      this.selectedTransaction = [
        {
          ...val,
          currency: val.currency.currency,
          amount: this.numberWithCommas(val.amount),
        },
      ]
    },
    setBonusCode(code) {
      this.bonusCode = code
    },
  },
}
</script>
<style scoped>
.bg-card {
  background-color: #00b983;
}

.card-border {
  border-right: 1px solid #e3e1e9;
}

.transaction-card {
  background-color: #efefef;
  padding: 15px;
  border-radius: 5px;
}

.card-bg {
  background-color: #172238;
  padding: 15px;
  border-radius: 5px;
}
</style>
