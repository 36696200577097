<template>
  <b-alert
    :show="isShow"
    :variant="alertVariant"
    class="p-2"
  >
    <div v-html="alertText" />
    <b-button
      :variant="buttonVariant"
      class="mt-2 mr-1"
      @click="$router.push({name: routeName})"
    >
      {{ buttonText }}
    </b-button>
  </b-alert>
</template>
<script>
import { BAlert, BButton } from 'bootstrap-vue'

export default {
  components: {
    BAlert,
    BButton,
  },
  props: {
    routeName: {
      type: String,
      default() {
        return '#'
      },
    },
    buttonVariant: {
      type: String,
      default() {
        return 'primary'
      },
    },
    alertText: {
      type: String,
      default() {
        return this.$t('redirect_alert_text')
      },
    },
    isShow: {
      type: Boolean,
      default() {
        return false
      },
    },
    alertVariant: {
      type: String,
      default() {
        return 'primary'
      },
    },
    buttonText: {
      type: String,
      default() {
        return this.$t('countinue')
      },
    },
  },
}
</script>

  <style scoped>
  .logo {
    width: 150px;
  }
  </style>
